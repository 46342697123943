import React from "react"
import * as THREE from 'three'
import Stats from "stats-js"
import { TweenMax, Power4 } from "gsap/TweenMax"
import { isMobile } from 'react-device-detect'
import raf from 'raf'
import "./ParticleCanvas.scss"

// Import Noises
import * as noiseOne from "./shapes/noise1.json"
import * as noiseTwo from "./shapes/noise2.json"

// Import Shapes
import * as random from "./shapes/random.json"

import * as hand from "./shapes/6000/hand.json"
import * as phone from "./shapes/6000/phone.json"
import * as person from "./shapes/6000/person.json"
import * as mask from "./shapes/6000/mask.json"
import * as sign from "./shapes/6000/sign.json"
import * as recycle from "./shapes/6000/recycle.json"
import * as rocket from "./shapes/6000/rocket.json"

const { detect } = require('detect-browser')
const browser = detect()

// Number of particles matches pre-defined geometry points
const noiseCount = 300,
      particleCount = 6000

let noiseParticles = [
  new THREE.Geometry(),
  new THREE.Geometry()
]

let randomParticles = new THREE.Geometry(),
    handParticles = new THREE.Geometry(),
    phoneParticles = new THREE.Geometry(),
    personParticles = new THREE.Geometry(),
    maskParticles = new THREE.Geometry(),
    signParticles = new THREE.Geometry(),
    recycleParticles = new THREE.Geometry(),
    rocketParticles = new THREE.Geometry()

// Create Noises
createShape(noiseCount, noiseParticles[0], noiseOne);
createShape(noiseCount, noiseParticles[1], noiseTwo);

// Create Shapes
createShape(particleCount, randomParticles, random);
createShape(particleCount, handParticles, hand);
createShape(particleCount, phoneParticles, phone);
createShape(particleCount, personParticles, person);
createShape(particleCount, maskParticles, mask);
createShape(particleCount, signParticles, sign);
createShape(particleCount, recycleParticles, recycle);
createShape(particleCount, rocketParticles, rocket);

// Create a shape from JSON object of points
function createShape(count, particlesVariable, shape) {
  for (var x = 0; x < count; x++) {
    if (!shape.default[x]) break;
    var vertex = new THREE.Vector3();
        vertex.x = shape.default[x]['x'];
        vertex.y = shape.default[x]['y'];
        vertex.z = shape.default[x]['z'];

    particlesVariable.vertices.push(vertex);
  }
}

// Shape lookup to match references within articles to objects
const shapes = {
  'random': randomParticles,
  'hand': handParticles,
  'phone': phoneParticles,
  'person': personParticles,
  'mask': maskParticles,
  'sign': signParticles,
  'recycle': recycleParticles,
  'rocket': rocketParticles
};

const shapeZRanges = {
  'random': 4,
  'hand': 1.75,
  'phone': 2.5,
  'person': 1.75,
  'mask': 1.5,
  'sign': 1.25,
  'recycle': 1.5,
  'rocket': 3
};

class ParticleCanvas extends React.Component {
  constructor(props) {
    super(props)

    // Defaults
    this.defTabletCamTargetY = -1
    this.defDesktopCamTargetY = 0

    this.startMobileCamPosZ = 15
    this.defMobileCamPosZ = 10

    this.startTabletCamPosZ = 15
    this.defTabletCamPosZ = 6

    this.startDesktopCamPosZ = 15
    this.defDesktopCamPosZ = 6

    this.noiseAnimating = false
    this.particlesAnimating = false

    this.spinning = false
  }

  componentDidMount() {
    this.cameraXBase = 0
    this.cameraYBase = 0
    this.cameraZBase = (window.innerWidth <= 1024 ?
      (window.innerWidth <= 768 ? this.startMobileCamPosZ : this.startTabletCamPosZ) :
      this.startDesktopCamPosZ
    )

    this.cameraPosX = this.cameraXBase
    this.cameraPosY = this.cameraYBase
    this.cameraPosZ = this.cameraZBase

    this.tCameraPosX = this.cameraPosX
    this.tCameraPosY = this.cameraPosY
    this.tCameraPosZ = this.cameraPosZ

    this.tCameraPosZ = (window.innerWidth <= 1024 ?
      (window.innerWidth <= 768 ? this.defMobileCamPosZ : this.defTabletCamPosZ) :
      this.defDesktopCamPosZ
    )

    this.cameraXTargetBase = 0 // -.3
    this.cameraYTargetBase = (window.innerWidth < 1024 ?
      this.defTabletCamTargetY :
      this.defDesktopCamTargetY
    )

    this.cameraXTarget = this.cameraXTargetBase
    this.cameraYTarget = this.cameraYTargetBase

    this.tCameraXTarget = this.cameraXTarget
    this.tCameraYTarget = this.cameraYTarget

    this.cameraMovementLimiter = .05
    this.cameraTargetLimiter = .008
    this.cameraScrollLimiter = .1

    this.currentShape = 0
    this.currentNoise = 0

    this.noiseXOffset = 0
    this.noiseYOffset = 0
    this.noiseZOffset = 0

    this.particleXOffset = .065
    this.particleYOffset = .065
    this.particleZOffset = .065

    this.sparkles = []
    this.sparkled = true

    this.centerYAxis = new THREE.Vector3(0,1,0)

    this.targetRotation = 0

    this.initCanvas()
    window.addEventListener('resize', this.resizeCanvas)
    // document.addEventListener('keydown', this.checkSequence)

    if (!isMobile) window.addEventListener('mousemove', this.mouseTrack)

    this.listenerKeys = {
      37: 'left',
      38: 'up',
      39: 'right',
      40: 'down',
      65: 'a',
      66: 'b'
    }

    this.kCode = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a']

    this.kCodePosition = 0
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCanvas)
    if (!isMobile) window.removeEventListener('mousemove', this.mouseTrack)
    cancelAnimationFrame(this.animationFrame)
  }

  componentDidUpdate(prevProps) {
    if (this.props.context.data.accessibility) {
      window.removeEventListener('resize', this.resizeCanvas)
      if (!isMobile) window.removeEventListener('mousemove', this.mouseTrack)
      cancelAnimationFrame(this.animationFrame)
    }

    if (this.props.context.data.shape !== this.currentShape)
      this.animateToShape(shapes[this.props.context.data.shape])

    // if (this.props.context.data.xPos !== this.tCameraXTarget)
    //   this.tCameraXTarget = this.props.context.data.xPos
  }

  initCanvas = () => {
    const width = window.innerWidth,
          height = (isMobile ? window.innerHeight + 300 : window.innerHeight) // Add a browser resize buffer if mobile/tablet

    // Add Scene
    this.scene = new THREE.Scene();

    // Add Camera
    this.camera = new THREE.PerspectiveCamera(50, width / height, 1, 1000);
    this.camera.position.x = this.cameraPosX;
    this.camera.position.y = this.cameraPosY;
    this.camera.position.z = this.cameraPosZ;

    // Add Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    });

    this.renderer.setPixelRatio(window.devicePixelRatio)

    if (browser ) {
      if (browser.name === "safari") {
        this.renderer.setPixelRatio(1)
      }
    }

    this.renderer.setSize(width, height)
    this.renderer.setClearColor(0x1125AA)

    this.renderer.powerPreference = "high-performance"
    this.renderer.shadowMap.enabled = false

    // this.renderer.gammaFactor = 2.2
    // this.renderer.gammaInput = true
    // this.renderer.gammaOutput = true

    this.renderer.domElement.style.top = (isMobile ? "-150px" : "0")

    this.mount.appendChild(this.renderer.domElement);

    this.setupCanvas()
  }

  setupCanvas = () => {
    this.addNoise()
    this.addParticles()
    this.addStats()
    // this.selectSparkles()
    // setInterval(this.selectSparkles, 500)
    this.animationFrame = raf(this.animate)
  }

  addStats = () => {
    this.stats = new Stats()
    this.stats.showPanel(0)
  }

  resizeCanvas = () => {
    let width = window.innerWidth,
        height = window.innerHeight

    let current = this.renderer.getSize()

    if (isMobile && current.width === width && current.height > height) return

    height = (isMobile ? window.innerHeight + 300 : window.innerHeight) // Add a browser resize buffer if mobile/tablet
    this.renderer.domElement.style.top = (isMobile ? "-150px" : "0")

    // Set Camera Position
    this.tCameraPosZ = (window.innerWidth <= 1024 ?
      (window.innerWidth <= 768 ? this.defMobileCamPosZ : this.defTabletCamPosZ) :
      this.defDesktopCamPosZ
    )

    // Set Camera Target
    this.tCameraYTarget = (window.innerWidth < 1024 ?
      this.defTabletCamTargetY :
      this.defDesktopCamTargetY
    )

    this.camera.aspect = width/height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }

  addNoise = () => {
    this.noiseBase = new THREE.Geometry();
    this.noiseCurrent = new THREE.Geometry();
    this.noiseOffsets = []

    for (let x = 0; x < noiseCount; x++) {
      let initialNoisePos = noiseParticles[0].vertices[x]

      const vertex = new THREE.Vector3(
        initialNoisePos.x,
        initialNoisePos.y,
        initialNoisePos.z
      )

      this.noiseBase.vertices.push(vertex);
      this.noiseCurrent.vertices.push(vertex);

      let offsetVertex = new THREE.Vector3((Math.random() * 2) - 1, Math.random() - .5, (Math.random() * 2) - 1);
      this.noiseOffsets.push(offsetVertex);
    }

    this.noiseMaterial = new THREE.PointsMaterial({
      color: 0xFFFFFF,
      size: .01
    })

    this.noiseSystem = new THREE.Points(
      this.noiseCurrent,
      this.noiseMaterial
    )

    this.noiseSystem.sortParticles = true;

    this.scene.add(this.noiseSystem);
    // this.setNoise();
  }

  setNoise = () => {
    clearTimeout(this.setNoiseTimeout)
    this.noiseAnimating = true
    this.setNoiseTimeout = setTimeout(this.setNoiseComplete, 3000)

    this.currentNoise = (this.currentNoise === 0) ? 1 : 0
    const noiseObject = noiseParticles[this.currentNoise]

    for (var i = 0; i < noiseObject.vertices.length; i++){
      TweenMax.to(this.noiseBase.vertices[i], 2.5, {ease: Power4.easeOut, x: noiseObject.vertices[i].x, y: noiseObject.vertices[i].y, z: noiseObject.vertices[i].z,
        delay: (i * .0002) })
    }
  }

  setNoiseComplete = () => {
    this.noiseAnimating = false
  }

  addParticles = () => {
    this.particlesBase = new THREE.Geometry();
    this.particlesCurrent = new THREE.Geometry();
    this.particlesCurrent.sparkle = []
    this.particleOffsets = []

    for (let x = 0; x < particleCount; x++) {
      let initialParticlePos = randomParticles.vertices[x]

      const vertex = new THREE.Vector3(
        initialParticlePos.x,
        initialParticlePos.y,
        initialParticlePos.z
      )

      this.particlesBase.vertices.push(vertex);
      this.particlesCurrent.vertices.push(vertex);

      let white = new THREE.Color(1,1,1);

      this.particlesCurrent.colors.push(white);
      this.particlesCurrent.sparkle.push(false);

      // const offsetVertex = new THREE.Vector3((Math.random() * 2) - 1, Math.random() - .5, (Math.random() * 2) - 1);
      this.particleOffsets.push(vertex);
    }

    this.particleMaterial = new THREE.PointsMaterial({
      vertexColors: THREE.VertexColors,
      size: .01,
      blending: THREE.AdditiveBlending,
      // transparent: true
    })

    this.particleSystem = new THREE.Points(
      this.particlesCurrent,
      this.particleMaterial
    )

    this.particleSystem.sortParticles = true
    this.particleSystem.rotation.z = Math.PI / 8

    this.scene.add(this.particleSystem)
    // this.animateToShape(randomParticles)
  }

  animateToShape = (particleObject) => {
    clearTimeout(this.animateToShapeTimeout)
    this.currentShape = this.props.context.data.shape
    this.particlesAnimating = true

    this.animateToShapeTimeout = setTimeout(this.animateToShapeComplete, 6000)

    this.particleOffsets.vertices = JSON.parse(JSON.stringify(particleObject.vertices))

    this.targetRotation = this.targetRotation + (Math.PI * 2)
  }

  animateToShapeComplete = () => {
    this.particlesAnimating = false
  }

  mouseTrack = (e) => {
    if (isMobile) return

    const xPerc = e.clientX / window.innerWidth,
          yPerc = e.clientY / window.innerHeight

    this.tCameraPosX = this.cameraXBase + (8 * (xPerc - .5))
    this.tCameraPosY = this.cameraYBase + (-4 * (yPerc - .5))
  }

  mouseLeave = () => {
    this.tCameraPosX = 0;
    this.tCameraPosY = 0;
  }

  updateCameraPosition = () => {
    this.cameraPosX += (this.tCameraPosX - this.cameraPosX) * this.cameraMovementLimiter
    this.cameraPosY += (this.tCameraPosY - this.cameraPosY) * this.cameraMovementLimiter
    this.cameraPosZ += (this.tCameraPosZ - this.cameraPosZ) * 0.01
    this.cameraXTarget += (this.tCameraXTarget - this.cameraXTarget) * this.cameraTargetLimiter
    this.cameraYTarget += (this.tCameraYTarget - this.cameraYTarget) * this.cameraScrollLimiter
    this.cameraYTarget = (Math.round(this.cameraYTarget * 10000) / 10000)

    this.camera.position.x = this.cameraPosX
    this.camera.position.y = this.cameraPosY
    this.camera.position.z = this.cameraPosZ
    this.camera.lookAt(new THREE.Vector3(this.cameraXTarget, this.cameraYTarget, 0))

    this.yCenter = this.cameraPosZ + .25
  }

  hslToRgb(h, s, l) {
    var r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        var hue2rgb = function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return new THREE.Color(r,g,b);
  }

  updateParticleColors = () => {
    for (var i = 0; i < this.particlesCurrent.vertices.length; i++) {
      let current = new THREE.Vector3(
        this.particlesCurrent.vertices[i].x,
        this.particlesCurrent.vertices[i].y,
        this.particlesCurrent.vertices[i].z
      )

      let angle = this.particleSystem.rotation.y

      let newPosition = current.applyAxisAngle(this.centerYAxis, angle)
      let zDistance = newPosition.distanceTo(this.camera.position)

      if (zDistance < this.yCenter) {
        if (this.particlesCurrent.sparkle[i]) continue
        this.particlesCurrent.colors[i] = new THREE.Color(1,1,1)
      } else {
        let distanceFromCenter = zDistance - this.yCenter
        let range = shapeZRanges[this.props.context.data.shape]
        let distanceRatio = distanceFromCenter / range
        let lightness = 1 - distanceRatio * 2

        lightness = (lightness > .02 ? lightness : .02)

        let newColor = this.hslToRgb(0, 0, lightness)

        this.particlesCurrent.colors[i] = newColor
      }
    }
  }

  selectSparkles = () => {
    for (var i = 0; i < this.particlesCurrent.vertices.length; i++){
      this.particlesCurrent.sparkle[i] = false
    }

    for (var x = 0; x < 100; x++) {
      let randomParticle = Math.ceil(Math.random() * 3000)
      this.particlesCurrent.sparkle[randomParticle] = true

      this.particlesCurrent.colors[randomParticle] = new THREE.Color(
        Math.random(),
        Math.random(),
        Math.random()
      )
    }
  }

  checkSequence = (e) => {
    const key = this.listenerKeys[e.keyCode]

    const requiredKey = this.kCode[this.kCodePosition]

    if (key === requiredKey) {
      this.kCodePosition++;

      if (this.kCodePosition === this.kCode.length) {
        this.spin()
        this.kCodePosition = 0
      }
    } else {
      this.kCodePosition = 0
    }
  }

  spin = () => {
    console.log('Hello!')
  }

  stopSpin = () => {
  }

  animate = () => {
    for (var i = 0; i < this.particlesCurrent.vertices.length; i++){
      this.particlesCurrent.vertices[i].x += (this.particleOffsets.vertices[i].x - this.particlesCurrent.vertices[i].x) * this.particleXOffset
      this.particlesCurrent.vertices[i].y += (this.particleOffsets.vertices[i].y - this.particlesCurrent.vertices[i].y) * this.particleYOffset
      this.particlesCurrent.vertices[i].z += (this.particleOffsets.vertices[i].z - this.particlesCurrent.vertices[i].z) * this.particleZOffset
    }

    // Update camera position
    this.tCameraYTarget = this.props.context.data.yPos
    this.updateCameraPosition()

    // Rotate entire system
    if (this.spinning)
      this.noiseSystem.rotation.y += .2
    else
      this.noiseSystem.rotation.y += .004

    this.particleSystem.rotation.y += (this.targetRotation - this.particleSystem.rotation.y) * .01

    // Ensure noise vertices update if moving
    if (this.noiseAnimating)
      this.noiseCurrent.verticesNeedUpdate = true

    // Ensure particles vertices update if moving
    if (this.particlesAnimating)
      this.particlesCurrent.verticesNeedUpdate = true

    // Update particle colours
    this.updateParticleColors()
    this.particlesCurrent.colorsNeedUpdate = true

    this.renderer.render(this.scene, this.camera)

    this.animationFrame = raf(this.animate)
  }

  render() {
    return (
      <div
        className="particle-canvas"
        ref={mount => (this.mount = mount)}
      />
    )
  }
}

export default ParticleCanvas