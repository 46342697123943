module.exports = [{
      plugin: require('/Applications/MAMP/htdocs/nesta/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1360665-11"},
    },{
      plugin: require('/Applications/MAMP/htdocs/nesta/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/Applications/MAMP/htdocs/nesta/src/layouts/index.js"},
    },{
      plugin: require('/Applications/MAMP/htdocs/nesta/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Applications/MAMP/htdocs/nesta/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
