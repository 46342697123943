// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Applications/MAMP/htdocs/nesta/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-long-form-js": () => import("/Applications/MAMP/htdocs/nesta/src/templates/long-form.js" /* webpackChunkName: "component---src-templates-long-form-js" */),
  "component---src-templates-feature-gallery-js": () => import("/Applications/MAMP/htdocs/nesta/src/templates/feature-gallery.js" /* webpackChunkName: "component---src-templates-feature-gallery-js" */),
  "component---src-templates-video-js": () => import("/Applications/MAMP/htdocs/nesta/src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-long-form-gallery-js": () => import("/Applications/MAMP/htdocs/nesta/src/templates/long-form-gallery.js" /* webpackChunkName: "component---src-templates-long-form-gallery-js" */),
  "component---src-templates-emails-js": () => import("/Applications/MAMP/htdocs/nesta/src/templates/emails.js" /* webpackChunkName: "component---src-templates-emails-js" */),
  "component---src-templates-accessible-js": () => import("/Applications/MAMP/htdocs/nesta/src/templates/accessible.js" /* webpackChunkName: "component---src-templates-accessible-js" */),
  "component---src-pages-404-js": () => import("/Applications/MAMP/htdocs/nesta/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Applications/MAMP/htdocs/nesta/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-text-js": () => import("/Applications/MAMP/htdocs/nesta/src/pages/text.js" /* webpackChunkName: "component---src-pages-text-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Applications/MAMP/htdocs/nesta/.cache/data.json")

