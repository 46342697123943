import React from "react"

const defaultContext = {
  data: {
    homeInit: false,
    homeScroll: 0,
    shape: 'random',
    yPos: 0,
    homeChapter: 1,
    chapters: 0,
    karma: 2
  },
  set: () => {},
}

const { Provider, Consumer } = React.createContext(defaultContext)

class ContextProvider extends React.Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContext,
      set: this.setData
    }

    this.yOffset = 0
  }

  setData(newData, callback) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }), callback)
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { Consumer as default, ContextProvider }