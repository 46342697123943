import React from "react"
import { ContextProvider } from "./Context"
import Consumer from "./Context"
import ParticleCanvas from "../components/ParticleCanvas/ParticleCanvas"

require("../resources/styles/style.scss")

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      keyboardInit: false
    }

    this.handleFirstTab = this.handleFirstTab.bind(this)
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleFirstTab)
  }

  handleFirstTab(e) {
    if (e.keyCode === 9 && !this.state.keyboardInit) {
      this.setState({
        keyboardInit: true
      }, () => {
        console.log('KEYBOARD USER')
        document.body.classList.add('user-is-tabbing')
        window.removeEventListener('keydown', this.handleFirstTab)
      })
    }
  }

  render() {
    return (
      <ContextProvider>
        <Consumer>
          {context =>
            <>
              <ParticleCanvas context={context} />
            </>
          }
        </Consumer>

        <div className="site-wrap">
          <div>
            {this.props.children}
          </div>
        </div>

      </ContextProvider>
    )
  }
}

export default Layout;